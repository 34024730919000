<template>

  <div class="DetailStudent div-template">
    <div class="text-center">
      <v-overlay :value="overlay"> 
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    
    <div class="text-center h4-title">
      <h3>{{$t('StudentDetail')}}</h3>
    </div>

      <v-text-field
      v-model="parent_email" 
      type="text"
        :label="$t('parent_email')"
        outlined
        readonly
    ></v-text-field>

    <v-text-field
      v-model="parent_name" 
      type="text"
        :label="$t('parent_name')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="student_email" 
      type="text"
        :label="$t('student_email')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_first_name" 
      type="text"
        :label="$t('first_name')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_last_name" 
      type="text"
        :label="$t('last_name')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_phone_cid" 
      type="text"
        outlined
        readonly
        :label="$t('phone_cid')"
    ></v-text-field>

      <v-text-field
      v-model="psg_phone_number" 
      type="text"
      :label="$t('phoneNumber')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_birthday" 
      type="text"
      :label="$t('birthday')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_relationship" 
      type="text"
      :label="$t('relationship')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_gender" 
      type="text"
      :label="$t('gender')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="psg_registered_date" 
      type="text"
      :label="$t('registered_date')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="student_idn" 
      type="text"
      :label="$t('student_idn')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="student_school_name" 
      type="text"
      :label="$t('student_school_name')"
        outlined
        readonly
    ></v-text-field>
    
      <v-text-field
      v-model="student_approval_date" 
      type="text"
      :label="$t('student_approval_date')"
        outlined
        readonly
    ></v-text-field>

      <v-text-field
      v-model="student_registration_date" 
      type="text"
      :label="$t('student_registration_date')"
        outlined
        readonly
    ></v-text-field>
    <br><br>

    <div class="footer">
      <br>
      <br>
      <v-btn 
        block
        rounded
        :name="$t('done')" 
        color="primary"
        @click="clickDone()">
          {{("done")}}
      </v-btn>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

  export default {
    name: 'DetailStudent',
    
    data() {
      return {
        overlay: false,
        parent_email: "",
        parent_name: "",
        student_email: "",
        psg_first_name: "",
        psg_last_name: "",
        psg_phone_cid: "",
        psg_phone_number: "",
        psg_birthday: "",
        psg_relationship: "",
        psg_gender: "",
        psg_registered_date: "",
        student_idn: "",
        student_school_name: "",
        student_approval_date: "",
        student_registration_date: "",
        alignments: [
        'start',
        'center',
        'end',
      ],
      }
    },
    methods:{
      clickDone(){
        this.$router.push(
          {name: "ParentalAccess"});
      },
    },
    mounted(){
      setTimeout(() => {
      this.overlay = true
      const payload = {
          student_email: this.$route.params.items,
      };

      this.$store
        .dispatch("childlinkage/getDetailStudent", payload)
        .then((response) => {
          let myData = response

          this.parent_email = myData.parent_email;
          this.parent_name = myData.parent_name;
          this.student_email = myData.student_email;
          this.psg_first_name = myData.psg_first_name;
          this.psg_last_name = myData.psg_last_name;
          this.psg_phone_cid = myData.psg_phone_cid;
          this.psg_phone_number = myData.psg_phone_number;
          // this.psg_birthday = myData.psg_birthday;
          
          this.psg_birthday = moment(myData.psg_birthday).format("YYYY-MM-DD");
          this.psg_relationship = myData.psg_relationship;
          this.psg_gender = myData.psg_gender;
          this.psg_registered_date = moment(myData.psg_registered_date).format("YYYY-MM-DD");
          this.student_idn = myData.student_idn;
          this.student_school_name = myData.student_school_name;
          if (myData.student_approval_date != "") {
            this.student_approval_date =  moment(myData.student_approval_date).format("YYYY-MM-DD");
          }
          this.student_registration_date = moment(myData.student_registration_date).format("YYYY-MM-DD");
          this.overlay = false
        })
        .catch((err) => {
          console.log(err);
        });

      }, 300)
    }
  }
</script>

<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.label-minus {
  padding-left: -20px;
}

.h4-title{
  padding-top: 10px;
  padding-bottom: 10px;
}
.onHover{
  cursor: pointer;
}
.end{
  margin-right: 0px;
}


.btn-bottom{
  position: fixed;
  bottom:   0;
  width: 95%;
  margin-bottom: 15px;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

</style>